import React, {useState, useEffect} from 'react'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "./topStocks.css";
import Card from './card';
import axiosInstance from '../../../utils/axiosInstance';

export default function TopStocks() {
  const [topStocks, setTopStocks] = useState([]);
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <MdKeyboardArrowRight color="#888" />,
        prevArrow: <MdKeyboardArrowLeft color="#888" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true
            }
          }
        ]
    };

    const fetchTopStocksBlogs = async() => {
      try {
        const response = await axiosInstance.get("/blog/readNews");
        const news = [];
        for(var i in response.data.data) {
          news.push({title: response.data.data[i].title, link: "/" + response.data.data[i].url_add_on})
        }
        setTopStocks(news);
      }catch(err) {
        console.error(err);
      }
    }

    useEffect(() => {
      fetchTopStocksBlogs();
    }, [])
    if(topStocks.length > 1) return (
      <div style={{width:"100%"}}>
          <div className="carousel-container" style={{width:"85%", margin:"auto", marginBottom:50}}>
              <h2 style={{marginTop:0, marginBottom:20}}>Top Stocks</h2>
              <Slider {...settings}>
                {topStocks.map((item, index) => (
                  <Card key={index} item={item}/>
                ))}
              </Slider>
          </div>
        </div>
    )
    else if (topStocks.length === 1) return (
      <div style={{width:"100%"}}>
        <div className="carousel-container" style={{width:"85%", margin:"auto", marginBottom:50}}>
            <h2 style={{marginTop:0, marginBottom:20}}>Top Stocks</h2>
            <Card  item={topStocks[0]} />
        </div>
      </div>
    )
    else return (
      <></>
    )
}
