import React, {useState, useEffect, useRef } from 'react';
import './aristocrat.css'; 
import axiosInstance from '../../utils/axiosInstance';
import LargeTable from '../Layout/LargeTable/largeTable';
import { Helmet } from 'react-helmet';

const Aristocrat = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [open, setOpen] = useState(false);
    const [aristocratsArr, setAristocratsArr] = useState([])
    const [aristocratsTable, setAristocratsTable] = useState([])
    const contentRef = useRef(null);

    const handleScroll = () => {
        if (window.scrollY > 80) {
        //   console.log(window.scrollY)
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };

    const getAristocratsData = async() => {
      try {
        const response = await axiosInstance.get("/symbol/allAristocrats");
        setAristocratsArr(response.data.data);
        // console.log(response.data.data);
      }catch(err) {
        console.error(err);
      }
    }

    const getAristocratsTableData = async() => {
      try {
        const response = await axiosInstance.get("/blog/getAristocratsTableData");
        setAristocratsTable(response.data.data);
      }catch(err) {
        console.error(err);
      }
    }
    useEffect(() => {
        getAristocratsData();
        getAristocratsTableData();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  return (
    <div className="container">
    {/* <Helmet>
      <title>Dividend Aristocrats List - by Yield & Payout Ratio</title>
      <meta name="description" content="65 Dividend Aristocrats revealed for sustainable growth. Dive into wealth-building with insights on Dividend Aristocrats." />
    </Helmet> */}
      <div className={`sidebar ${isSticky ? 'sticky' : ''}`}>
        <div className='index'>
          <h2>Table of Contents</h2>
          <ul>
            <li><a href="#section1">1. Dividend Aristocrats List</a></li>
            <li><a href="#section2">2. Investment Strategy</a></li>
            <li><a href="#section3">3. Factors to Consider</a></li>
          </ul>
        </div>
        <div className='indexMob'>
            <div className="accordion-item-aristocrats" style={{background:"#fff"}}>
              <button style={{background:"white", borderBottom:"1px solid #ccc", paddingBottom:7}} onClick={() => setOpen(!open)}><div style={{display:"flex", justifyContent:"space-between" }}>
                Table of Contents <i className={`fas ${open ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
                </div></button>
              <div className={`dropdown ${open ? 'show' : ''}`}
                style={{ height: open ? contentRef.current.scrollHeight : '0px' }}>
                <ul ref={contentRef}>
                  <li><a href="#section1">1. Dividend Aristocrats List</a></li>
                  <li><a href="#section2">2. Investment Strategy</a></li>
                  <li><a href="#section3">3. Factors to Consider</a></li>
                </ul>
              </div>
            </div>
          </div>
      </div>
      <div className="content">
        <h2>Dividend Aristocrats List – by Yield & Payout Ratio</h2>
        <div className="tags">
          <h6>Tags: <span className="tag"><a href="/company-tag/aristocrats">Aristocrats</a></span></h6>
          
        </div>
        <p><strong>Ever wondered how certain companies consistently thrive, not just surviving market volatility but thriving through it?</strong></p>
        <p><strong>Let’s unravel the secret.</strong></p>
        <p>Dividend Aristocrats, elite companies with a consistent track record of paying and increasing dividends for at least 25 consecutive years, are often considered stable and reliable investments, providing investors with a reliable income stream and demonstrating a commitment to long-term financial strength and stability.</p>
        <h2>Dividend Aristocrats List (65 Dividend Aristocrats)</h2>
        
        {aristocratsTable?.length? <LargeTable data = {aristocratsTable} /> : <></>}

        <div id= "section1">
        {aristocratsArr.length && aristocratsArr.map((ele) => (
          <div>
            <h3>{ele.clean_name}</h3>
            <p>{ele.description}</p>
            <p>{">> "}<a href={"/c/" + ele.url_add_on}>{ele.clean_name} Dividend History</a></p>
          </div>
        ))}
        </div>
        
        <h2 id="section2">Dividend Aristocrats List (65 Dividend Aristocrats)</h2>
        <p>Investing in dividend aristocrats, companies with a remarkable track record of consistently increasing dividends for at least 25 years, can be a sound financial strategy. These elite entities showcase financial stability, strong management, and a commitment to shareholder value.</p>
        <p>The allure lies in the combination of regular income and the potential for long-term growth, making dividend aristocrats an appealing option for investors seeking a reliable and rewarding investment experience. As these companies have weathered economic uncertainties while rewarding investors with increasing dividends, they often represent a prudent choice for those looking to build a resilient and income-generating portfolio.</p>
        
        <h2 id="section3">Factors to Consider when Investing in Dividend Aristocrats</h2>
        <p>Investing in Dividend Aristocrats demands a strategic approach. Start by evaluating the financial health of the companies, emphasizing a history of consistent dividend growth and a sustainable payout ratio. A robust and stable dividend track record is indicative of a company’s resilience and commitment to shareholder value.</p>
        <p>Furthermore, consider the broader economic landscape. Industries can be cyclical, and economic trends influence dividend stability. Diversification across sectors helps mitigate risks, ensuring a more balanced and reliable dividend portfolio. By combining these factors and adopting a long-term perspective, investors can navigate the world of Dividend Aristocrats with greater confidence and increase the potential for lasting returns.</p>
      
        <h2 style={{marginTop:"60px"}}>Articles related to this</h2>
        <p>{">> "}<a href="/">Top 52 Dividend Kings List</a></p>
        <p>{">> "}<a href="/">Warren Buffett’s Top 36 Dividend Stocks</a></p>
        <p>{">> "}<a href="/">Top 45 – 10-Year Dividend Growth Stocks</a></p>

      </div>
      <div className="empty-section"></div>
    </div>
  );
};

export default Aristocrat;
