import React, {useState, useEffect, useRef } from 'react'
import "./blogFaq.css"
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi2";
import axiosInstance from '../../../utils/axiosInstance';

export default function BlogFaq({symbol, heading}) {
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRefs = useRef([]);
    const [faqs, setFaqs] = useState([]); 
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get("/symbol/faq?", {
            params : {symbol: symbol}
          });
          setFaqs(response.data.data);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchData();
    }, []);

    const formatText = (text) => {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    };
  
  return (
    <div className="faq-container">
    <h2><span className='underline'>{heading}</span> Dividend FAQ</h2>
    {faqs.map((faq, index) => (
      <div key={index} className="faq-item">
        <div className="faq-question" onClick={() => toggleAccordion(index)}>
          <h5>{faq.question}</h5>
          <span style={{width: "24px", height: "24px"}}>{activeIndex === index ? <HiOutlineMinusCircle size={24} /> : <HiOutlinePlusCircle size={24} />}</span>
        </div>
        <div
            ref={(el) => contentRefs.current[index] = el}
            className={`faq-answer ${activeIndex === index ? 'active' : ''}`}
            style={{
              maxHeight: activeIndex === index ? `${contentRefs.current[index].scrollHeight}px` : '0px'
            }}
          >
            {formatText(faq.answer)}
          </div>
      </div>
    ))}
  </div>
  )
}
