import React from 'react'
import { Helmet } from 'react-helmet'
export default function Disclaimer() {
  return (
    <div>
    {/* <Helmet>
      <title>Disclaimer - idividends</title>
    </Helmet> */}
    <div style={{color: "#181818", marginBottom: "5rem", fontWeight: 500, lineHeight: 1.2, padding: "20px 12%", display: "flex", flexDirection: "column"}}>
      <h2 style={{margin: "35px 0", fontSize:"32px"}}>Disclaimer</h2>
      <p style={{fontSize:"16px", paddingLeft:"10px"}}>The content on iDividendStock is intended solely for informational and research purposes and should not be construed as trade or investment recommendations. We assume no responsibility for any decisions made or actions taken based on this content. Our users are encouraged to conduct independent investigations and exercise sound judgment before making any financial decisions.</p>
    </div>
    </div>
  )
}
