import React from 'react'
import "./table.css"
export default function table({data, headings, isNameRequired}) {
  return (
    <div className='tableContainer'>
    <table>
      <thead>
        <tr>
        {
          headings.map((ele, index) => {
            return (
              <th>{ele}</th>
            )
          })
        }
        </tr>
      </thead>
      <tbody>
        {data.map((ele, index) => {
          return (
            <tr key={index} className={index%2 === 0? "lightGrey" : "white"}>
              {isNameRequired? <td><a href={"/c/" + ele[0]}  className='stockName'><b>{ele[1]}</b></a></td>: <></>}
              {ele.map((td, ind) => { return (
                isNameRequired && ind < 2? <></> : <td>{td}</td> 
              )
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  )
}
