import React, {useState, useEffect, useRef} from 'react'
import axiosInstance from '../../utils/axiosInstance';
import { useParams } from 'react-router-dom';
import TopStocks from '../Layout/TopStocks/topStocks';
import { Helmet } from 'react-helmet';

export default function BlogTag() {
    const {tag} = useParams();

    const [isSticky, setIsSticky] = useState(false);
    const [open, setOpen] = useState(false);
    const contentRef = useRef(null);
    const [userBlogs, setUserBlogs] = useState([]);
    const handleScroll = () => {
        if (window.scrollY > 80) {
        //   console.log(window.scrollY)
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };
      const shortenPara = (para, count) => {
        if(!para) return '';
        const words = para.split(' ');
        if(words.length <= count) {
            return para;
        }
        return words.slice(0, count).join(' ') + '...';
      }
      const fetchUserBlogs = async() => {
        try {
            const response = await axiosInstance.post("blog/searchTags/" + tag);
            const blogs = [];
            const parser = new DOMParser();
            for(var i in response.data.data) {
                const cur = response.data.data[i];
                const content = cur.content;
                const doc = parser.parseFromString(content, 'text/html');
                const firstP = doc.querySelector('p');
                const textContent = firstP?.textContent;
                blogs.push({title: cur.title, description: textContent, link: "/" + cur.url_add_on, tags:cur.tags})
            }
            setUserBlogs(blogs);
        }catch(err) {
            console.error(err);
        }
      }
      const convertTagUrl = (tag) => {
        if(!tag) return "/";
        tag = tag.replace(" ", "-");
        tag = tag.toLowerCase();
        return "/tag/"+tag;
      }
      const convertUrlTag = (url) => {
        if(!url) return "";
        return url
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
    useEffect(() => {
        fetchUserBlogs();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div>
    {/* <Helmet>
      <title>{convertUrlTag(tag) + " Archives -"}</title>
    </Helmet> */}
    <div className="container">
      <div className={`sidebar ${isSticky ? 'sticky' : ''}`}>
        <div className='index'>
          <h2>Table of Contents</h2>
          <ul>
            <li><a href="#section1">1. Dividend Aristocrats List</a></li>
            <li><a href="#section2">2. Investment Strategy</a></li>
            <li><a href="#section3">3. Factors to Consider</a></li>
          </ul>
        </div>
        <div className='indexMob'>
            <div className="accordion-item-aristocrats" style={{background:"#fff"}}>
              <button style={{background:"white", borderBottom:"1px solid #ccc", paddingBottom:7}} onClick={() => setOpen(!open)}><div style={{display:"flex", justifyContent:"space-between" }}>
                Table of Contents <i className={`fas ${open ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
                </div></button>
              <div className={`dropdown ${open ? 'show' : ''}`}
                style={{ height: open ? contentRef.current.scrollHeight : '0px' }}>
                <ul ref={contentRef}>
                  <li><a href="#section1">1. Dividend Aristocrats List</a></li>
                  <li><a href="#section2">2. Investment Strategy</a></li>
                  <li><a href="#section3">3. Factors to Consider</a></li>
                </ul>
              </div>
            </div>
          </div>
      </div>
      <div className="content">
        <h2>Blog</h2>
        
        {userBlogs.length && userBlogs.map((ele) => (
          <div style={{marginTop:36}}>
            <h3><a className='blogHeading' href={ele.link}>{ele.title}</a></h3>
            <p className='blogContentShort'>{shortenPara(ele.description, 60)}</p>
            <div style={{padding:"0 10px"}} className="tags">
                <h6 style={{margin:"10px 0"}}>Tags: 
                    {ele.tags.map((tag, index) => {
                        return (
                            <span className="tag"><a href={convertTagUrl(tag)}>{tag}</a></span>
                        )
                    })}
                </h6>
            </div>
          </div>
        ))}

      </div>
      <div className="empty-section"></div>
    </div>
    <TopStocks />
    </div>
  )
}
