import React, {useEffect, useState, useRef} from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import TopStocks from '../Layout/TopStocks/topStocks';
import { Helmet } from 'react-helmet';
import LargeTable from "../Layout/LargeTable/largeTable"

export default function Blog() {
    const [isSticky, setIsSticky] = useState(false);
    const [open, setOpen] = useState(false);
    const contentRef = useRef(null);
    const handleScroll = () => {
        if (window.scrollY > 80) {
        //   console.log(window.scrollY)
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };

    const {blogName} = useParams();
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [tags, setTags] = useState([]);
    const [toc, setToc] = useState();
    const [url, setUrl] = useState();
    const [firstPara, setFirstPara] = useState();
    const [tableData, setTableData] = useState([]);
    const [metaString, setMetaString] = useState("");
    const [error, setError] = useState();
    const [data, setData] = useState();

    const fetchBlogData = async(blogName) =>  {
        try {
            const response = await axiosInstance.get("/blog/read", {params: {url_add_on: blogName}})
            console.log(response.data.data);
            const parser = new DOMParser();
            if(response.data.data) {
              const content = response.data.data.content;
              const doc = parser.parseFromString(content, 'text/html');
              const firstP = doc.querySelector('p');
              if(firstP) {
                setFirstPara(firstP.outerHTML);
                firstP.remove();
              }
              const updatedHtml = doc.body.innerHTML;
              setData({...response.data.data, content: updatedHtml})
                // setTitle(response.data.data.title);
                // setTags(response.data.data.tags);
                // setToc(response.data.data.table_of_contents);
                // setUrl(response.data.data.url_add_on);
                // setMetaString(response.data.data.metatag);
                // setTableData(response.data.data.table_data);
                setError(null);
            }else {
                setError(response.data.error)
            }
        }catch(err) {
            console.log(err);
            setError(err)
        }
    }
    const convertTagUrl = (tag) => {
      if(!tag) return "/";
      tag = tag.replace(" ", "-");
      tag = tag.toLowerCase();
      return "/tag/"+tag;
    }
    const parseMetaString = (metaString) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = metaString.trim();
      const metaTags = Array.from(tempDiv.children);
      return metaTags.map(tag => ({
        name: tag.getAttribute('name'),
        content: tag.getAttribute('content'),
      }));
    };
  
    // Parse the meta tags
    // const metaTags = parseMetaString(metaString);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        fetchBlogData(blogName);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    if(data?.title) {
        return (
          <div>
          {/* <Helmet> 
            <title>{title}</title>
            {metaTags.map((meta, index) => (
              <meta key={index} name={meta.name} content={meta.content} />
            ))}
          </Helmet> */}
            <div className="container">
              <div className={`sidebar ${isSticky ? 'sticky' : ''}`}>
                <div className='index'>
                  <h2>Table of Contents</h2>
                  <ul dangerouslySetInnerHTML={{ __html: data.table_of_contents }}>
                  </ul>
                </div>
                <div className='indexMob'>
                    <div className="accordion-item-aristocrats" style={{background:"#fff"}}>
                      <button style={{background:"white", borderBottom:"1px solid #ccc", paddingBottom:7}} onClick={() => setOpen(!open)}><div style={{display:"flex", justifyContent:"space-between" }}>
                        Table of Contents <i className={`fas ${open ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
                        </div></button>
                      <div className={`dropdown ${open ? 'show' : ''}`}
                        style={{ height: open ? contentRef.current.scrollHeight : '0px' }}>
                        <ul ref={contentRef} dangerouslySetInnerHTML={{ __html: data.table_of_contents }}>
                        </ul>
                      </div>
                    </div>
                  </div>
              </div>
              <div className='content'>
                <h2>{data.title}</h2>
                <div style={{padding:"0 10px"}} className="tags">
                  <h6 style={{margin:"10px 0"}}>Tags: 
                    {data.tags.map((tag, index) => {
                      return (
                        <span className="tag"><a href={convertTagUrl(tag)}>{tag}</a></span>
                      )
                    })}
                  </h6>
                </div>
                <div style={{padding:"0 10px"}} dangerouslySetInnerHTML={{ __html: firstPara }} />
                {data.table_data?.length? <LargeTable data = {data.table_data} /> : <></>}
                <div style={{padding:"0 10px"}} dangerouslySetInnerHTML={{ __html: data.content }} />
              </div>

              <div className="empty-section"></div>
            </div>
            <TopStocks />
          </div>
          )
    }else if(error) {
        return (
            <div style={{padding:"30px 12%"}}>
                <h2 style={{fontSize:"32px", padding:"30px 0"}}>404 Error Page</h2>
                <p style={{padding:"10px", fontSize:"16px", marginBottom:"40px"}}>Sorry, this page is not found ont this server</p>
            </div>
        )
    }
}
