import React from 'react'
import logo from "../../../images/logo-nav.png";
import "./footer.css"
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import FooterMobile from './footerMobile';

export default function Footer() {
  return (
    <div className="wrap">
    <FooterMobile />
    <footer className='footerContainer'>
        <div className="footer-content">
            <div className="footer-section about">
                <div className="logo"><img src={logo} alt="Logo" /></div>
                <p>idividendstocks is the dividend overview for shares with dividends paid per month, quarter or year including important dates such as ex-dividend and payment.</p>
                <div className="social">
                    <ul>
                        <li><a href="https://www.youtube.com/@iDividendStocks"><FaYoutube size={25} /></a></li>
                        <li><a href="https://www.instagram.com/idividendstocks/"><FaInstagram size={25}/></a></li>
                        <li><a href="https://x.com/idividendstocks"><FaTwitter size={25} /></a></li>
                        <li><a href="https://www.linkedin.com/company/idividendstocks/"><FaLinkedin size={25} /></a></li>
                        <li><a href="https://www.facebook.com/people/IDividendStocks/100092170903859/"><FaFacebookSquare size={25} /></a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-section links">
                <h5>Dividend international</h5>
                <ul>
                    <li><a href="/metdividend.nl">metdividend.nl</a></li>
                    <li><a href="/actionadividende.fr">actionadividende.fr</a></li>
                    <li><a href="/aktiedividende.de">aktiedividende.de</a></li>
                </ul>
            </div>
            <div className="footer-section links">
                <h5>High Dividend Stocks</h5>
                <ul>
                    <li><a href="/all-stocks">All Stocks</a></li>
                    <li><a href="/aristocrats">Dividend Aristocrats</a></li>
                    <li><a href="/monthly">Monthly Dividend Stocks</a></li>
                </ul>
            </div>
            <div className="footer-section links a">
                <ul>
                    <li><a href="/faqs"><b>FAQ</b></a></li>
                    <li><a href="/blog"><b>Blog</b></a></li>
                    <li><a href="/contact"><b>Contact</b></a></li>
                    <li><a href="/cookie-policy-privacy-statement/"><b>Cookie Policy & Privacy Statement</b></a></li>
                    <li><a href="/discaimer"><b>Disclaimer</b></a></li>
                </ul>
            </div>
        </div>
        <div className="footer-bottom">
            <p>&copy; 2024 idividendstocks.com. All Rights Reserved.</p>
        </div>
    </footer>
</div>
  )
}