import axios from 'axios';
// Create an instance of axios with a custom config

const axiosInstance = axios.create({
  baseURL: 'https://www.idividendstocks.com/api', 
  // timeout: 10000, 
  headers: { 'Content-Type': 'application/json' },
  maxBodyLength: Infinity, 
});

export default axiosInstance;
