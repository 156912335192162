import React, {useState, useEffect} from 'react'
import axiosInstance from '../../utils/axiosInstance';
// import Table from '../Layout/Table/table';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdOutlineCalendarMonth } from "react-icons/md";
import NewsCarousel from '../Layout/News/news';
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css"
import "./calendar.css"
import MoonLoader from "react-spinners/MoonLoader";

function Table({data, headings, isNameRequired}) {
  return (
    <div className='tableContainer'>
    <table>
      <thead>
        <tr>
        {
          headings.map((ele, index) => {
            return (
              <th>{ele}</th>
            )
          })
        }
        </tr>
      </thead>
      <tbody>
        {data.map((ele, index) => {
          return (
            <tr key={index} className={index%2 === 0? "lightGrey" : "white"}>
              {isNameRequired? <td><a href={"/c/" + ele[0]}  className='stockName'><b>{ele[1]}</b></a></td>: <></>}
              {isNameRequired? <td><a href={"/c/" + ele[0]}  className='stockName'>{ele[2]}</a></td>: <></>}
              {ele.map((td, ind) => { return (
                isNameRequired && ind < 3? <></> : <td>{td}</td> 
              )
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  )
}

export default function Dividendcalendar( ) {
  const [loading, setLoading] = useState(false)
  const [dateArr, setDateArr] = useState([]);
  const [calendar, setcalendar] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [pageinationString, setPaginationString] = useState("");
  const [curDate, setCurDate] = useState((new Date()).toISOString().split('T')[0])
  const [total, setTotal] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false)
  
  const initDates = async() => {
    const dates = [];
    let cur = (new Date()).toISOString().split('T')[0]
    const response = await axiosInstance.get("/symbol/dateBasedSymbolsMain?date=" + cur);
    console.log(response);
    const respData = response.data.data;
    for(var i in respData) {
      var isToday = curDate === respData[i].original_date? true : false;
      dates.push({ date: respData[i].formatted_date, isToday: isToday? true: false, date_url:respData[i].original_date});
    }
    setDateArr(dates);
  }
  const getcalendar = async() => {
    const response = await axiosInstance.get("/symbol/dateBasedSymbols?date=" + curDate + "&page=" + curPage);
    const data = response.data.data;
      const tableData = data.map(announcement => [
        announcement.url_add_on,
        announcement.symbol,
        announcement.clean_name,
        announcement.ex_div_date,
        announcement.dividend_amount,
        announcement.payment_date
      ]);
      
    setcalendar(tableData);
    setPaginationString(generatePaginationString(response.data.total, curPage));
    setTotal(response.data.total)
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true);
    initDates();
    getcalendar();
  }, [curDate, curPage]) 

  const updateDate = (ele) => {
    setCurDate(ele.date_url)
    setCurPage(1)
  } 
  function generatePaginationString(totalCount, pageNo, pageSize = 50) {
    const startIndex = (pageNo - 1) * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, totalCount);
    return `${startIndex}-${endIndex} of ${totalCount} results`;
  }
  console.log(curDate)
  return (
    <div>
    <div className='calendarDiv'>
        <h2><span className='underline'>Dividend Calendar</span> 2024 | Upcoming Ex-Dividend </h2>
        <div className='dates'>
            <h6>
              {dateArr.map((ele, index) => {
                return <span onClick={() => updateDate(ele, index)} className={ele.isToday? "todayDate date": "date"} key={index}>{ele.date} </span>
              })}
            </h6>
        </div>
        <div className="calendar">
          <div style={{marginBottom:30, display:"flex", justifyContent:"space-between"}}>
            <div>
              <span style={{fontSize:17}}>{pageinationString}</span>
            </div>
            <div style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
              <span onClick={() => setShowDatePicker(true)} style={{paddingRight:10}}>Select Date </span> <MdOutlineCalendarMonth onClick={() => setShowDatePicker(true)} size={24} />
              <DatePicker
                selected={curDate}
                onChange={(date) => {console.log(date); setCurDate(date.toISOString().split('T')[0])}}
                dateFormat="yyyy-MM-dd"
                open={showDatePicker}
                onClickOutside={() => setShowDatePicker(false)}
              />
            </div>

          </div>
          {!loading? 
          calendar.length !== 0 ?
          <Table data={calendar} headings={["Symbol", "Name", "Ex-Div Date", "Dividend amount", "Pay Date"]} isNameRequired={true} />
          :
          <div style={{padding: "30px 0",display:"flex", justifyContent:"center", alignItems:"center"}}>
            Oops! No data found.
          </div>
          : 
          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <MoonLoader
              color={"#22b273"}
              loading={loading}
              size={75}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }
        </div>
        <div className="pagination">
            <MdKeyboardArrowLeft style={{cursor:"pointer"}} onClick={() => {if (curPage !== 1) setCurPage(curPage - 1)}} size={32} color={curPage===1? "#aaa" : "#222"} /> 
            <span>{curPage}</span> 
            <MdKeyboardArrowRight style={{cursor:"pointer"}} onClick={() => {if(total>curPage*50)setCurPage(curPage + 1)}} size={32} color={total<=curPage*50? "#aaa" : "#222"}/>
        </div>            
        <div className='lastLine'>
            <span><strong>The companies in the list above are expected to go ex-dividend this week.</strong></span>
            <br />Keeping track of these dates is crucial for investors looking to maximize their returns. Our dividend calendar is designed to provide you with the most accurate and up-to-date information on dividend paying stocks. By regularly checking our ex-dividend calendar, you can stay informed about key dates and plan your investments accordingly.
            <br />
            <br />
            For those planning their investment strategies, our dividend calendar 2024 offers a comprehensive look at anticipated ex-dividend dates and payout schedules. This feature ensures that you never miss an important dividend announcement. Whether you are focusing on specific stocks or monitoring the broader market, our stock dividend calendar will help you stay on top of your portfolio and make informed decisions.
            <br />
            <br />
            Stay tuned for regular updates and use our dividend calendar as a reliable resource to navigate the complex landscape of dividend investing.
        </div>
    </div>
    <NewsCarousel />
    </div>
  );
};
