import React, {useState, useRef} from 'react'
import "./largeTable.css"

export default function LargeTable({data}) {  
    const [expandedRows, setExpandedRows] = useState([]);

    const toggleRow = (index) => {
        const isRowCurrentlyExpanded = expandedRows.includes(index);
        const newExpandedRows = isRowCurrentlyExpanded
        ? expandedRows.filter(id => id !== index)
        : [...expandedRows, index];
        setExpandedRows(newExpandedRows);
    };

  return (
    <div className='tableContainer' style={{marginBottom:"40px", marginTop:"40px"}}>
    <table>
      <thead>
        <tr>
            <th>Stock</th>
            <th>Dividend Yield</th>
            <th>Pay-out Ratio</th>
            <th>P/E Ratio</th>
            <th>P/B Ratio</th>
            <th>ROE</th>
            {/* <th>1y Dividend Growth</th> */}
            <th>5y Dividend Growth</th>
            <th>10y Dividend Growth</th>
        </tr>
      </thead>
      <tbody className='tableBody'>
      {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                <td style={{display:"flex", justifyContent:"space-between"}}>
                    <a className='stockName' href={"/c/" + item.url_add_on}><b>{item.clean_name}</b></a>
                    <button onClick={() => toggleRow(index)} className="expandBtn">
                        {expandedRows.includes(index) ? '▲' : '▼'}
                    </button>
                </td>
                <td>{item.dividend_yield}</td>
                <td>{item.payout_ratio}</td>
                <td>{item.pe_ratio}</td>
                <td>{item.pb_ratio}</td>
                <td>{item.roe}</td>
                {/* <td>{item["1_yr_div_growth"]}</td> */}
                <td>{item["5_yr_div_growth"]}</td>
                <td>{item["10_yr_div_growth"]}</td>
              </tr>
              {expandedRows.includes(index) && (
                <tr>
                  <td colSpan="8" className="description">{item.description}</td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}
