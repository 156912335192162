import React, {useState, useEffect} from 'react'
import logo from "../../../images/logo-nav.png"
import "./footerMobile.css"

export default function FooterMobile() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionToggle = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <footer className='footerMobileContainer'>
        <div className="footer-content-mobile">
          <div className="social-icons">
            <a href="https://www.youtube.com/@iDividendStocks"><i className="fab fa-youtube"></i></a>
            <a href="https://www.instagram.com/idividendstocks/"><i className="fab fa-instagram"></i></a>
            <a href="https://x.com/idividendstocks"><i className="fab fa-twitter"></i></a>
            <a href="https://www.linkedin.com/company/idividendstocks/"><i className="fab fa-linkedin"></i></a>
            <a href="https://www.facebook.com/people/IDividendStocks/100092170903859/"><i className="fab fa-facebook"></i></a>
          </div>
          <div className="accordion">
            {[
              { title: 'Dividend international', links: ['metdividend.nl', 'actionadividende.fr', 'aktiedividende.de'], href:["metdividend.nl", "actionadividende.fr", "aktiedividende.de"] },
              { title: 'High Dividend Stocks', links: ['All Stocks', 'Dividend Aristocrats', 'Monthly Dividend Stocks'], href:["all-stocks", "aristocrats", "monthly"] },
              { title: 'FAQ', links: [], href:"/faqs"},
              { title: 'Blog', links: [], href:"/blog" },
              { title: 'Contact', links: [], href:"/contact" },
              { title: 'Cookie Policy & Privacy Statement', links: [], href:"/cookie-policy-privacy-statement" },
              { title: 'Disclaimer', links: [], href:"/disclaimer" }
            ].map((item, index) => (
              <div className="accordion-item" key={index}>
              {item.links.length?
                <button className="accordion-button" onClick={() => handleAccordionToggle(index)}>
                  {item.title} <i className={`fas ${activeIndex === index ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
                </button>
               :
                <a className="accordion-button" href={item.href} >
                  {item.title} <i className="fas fa-chevron-right"></i>
                </a>
              }
                
                <div className="accordion-content" style={{ display: activeIndex === index ? 'block' : 'none' }}>
                  <ul>
                    {item.links.length? item.links.map((link, linkIndex) => (
                      <li key={linkIndex}><a href="/">{link}</a></li>
                    )) : <></>} 
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className="footer-logo">
            <img src={logo} alt="iDividendStocks Logo" />
            <p>iDividendStocks is the dividend overview for shares with dividends paid per month, quarter or year including important dates such as ex-dividend and payment.</p>
          </div>
          <div className="footer-bottom-mobile">
            <p>&copy; 2024 idividendstocks.com. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    );
};
  
