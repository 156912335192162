import React from 'react'
import "./card.css";
import { TbArrowUpRight } from "react-icons/tb";


export default function Card(props) {
  return (
    <div className='cardItem' style={{backgroundColor: props.item.bgColor, borderColor: props.item.border}}>
      <div className='greenBox'></div>
      <a href={props.item.link}>{props.item.title}</a>
      <div style={{position:"absolute", bottom:"0px", right:"1px"}}>
        <TbArrowUpRight size={30} className='arrowIconBottom'/>
      </div>
    </div>
  )
}
