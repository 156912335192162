import React, {useState, useEffect} from 'react'
import "./dividendCalc.css"
import axiosInstance from '../../utils/axiosInstance';

export default function DividendCalc( {cleanName, content} ) {
  const [dividendAmount, setDividendAmount] = useState('');
  const [sharePrice, setSharePrice] = useState('');
  const [frequency, setFrequency] = useState('');
  const [yieldResult, setYieldResult] = useState(null);
 
  const calculateYield = () => {
    const freqMultiplier = frequency;
    const yieldCalc = ((dividendAmount * freqMultiplier) / sharePrice) * 100;
    setYieldResult(yieldCalc.toFixed(2));
  };
  return (
    <div className="calculator-container">
      <h1 className="calculator-title"><span className='underline'>{cleanName}</span> Dividend Yield</h1>
      <p className="calculator-description">{content}</p>
      <div className="calculator-content">
        <div className="calculator-inputs">
          <div className="input-group">
            <label>Dividend Amount:</label>
            <input
              type="number"
              value={dividendAmount}
              onChange={(e) => setDividendAmount(e.target.value)}
            />
          </div>
          <div className='responsiveCalc'>
          <div className="input-group">
            <label>Dividend Frequency:</label>
            <select value={frequency} onChange={(e) => setFrequency(e.target.value)}>
              <option value="12">Monthly</option>
              <option value="4">Quarterly</option>
              <option value="2">Semi-Quarterly</option>
              <option value="1">Yearly</option>
            </select>
          </div>
          <div className="input-group">
            <label>Share Price:</label>
            <input
              type="number"
              value={sharePrice}
              onChange={(e) => setSharePrice(e.target.value)}
            />
          </div>
          </div>
          <button className="calculate-button" onClick={calculateYield}>Calculate Dividend Yield</button>
        </div>
        {yieldResult !== null && (
          <div className="yield-result">
            <h2>Dividend Yield:</h2>
            <div className="yield-result-value">{yieldResult}%</div>
          </div>
        )}
      </div>
    </div>
  );
};
