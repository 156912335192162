import React, {useState, useEffect} from 'react'
import "./stockVsStock.css";
import NewsCarousel from '../Layout/News/news';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import ComparisonCarousel from '../Layout/ComparisonCarousel/comparisonCarousel';
// import { Helmet } from 'react-helmet';

export default function StockVsStock() {
    const {stockVsStock} = useParams();
    const [data, setData] = useState(null);

    const fetchComparisionData = async() => {
        try {
            const response = await axiosInstance?.get("/versus/specificVs?url_add_on=" + stockVsStock);
            console.log(response.data.data)
            setData(response?.data?.data);
            
        }catch(err) {
            console.error(err);
        }
    }
    // console?.log(data);
    useEffect(() => {
        fetchComparisionData();
    }, [])
    if(data) {
        return (
            <div>
            {/* <Helmet>
                <title>{data?.meta_title}</title>
                <meta name="description" content={data?.intro_text?.substring(100) + "..."} ></meta>
            </Helmet> */}
            <div className='stockVsStockContainer'>
            {data? 
                <div className='introSection'>
                    <h2>{data?.meta_title?.split(":")[0]} <span className='underline'>{data?.meta_title?.split(":")[1]}</span></h2>
                    <p>{data?.intro_text}</p>
                </div>
            : <></>}
        
        <div className='comparision'>
            <div className='firstStock'>
                <h4 >{data?.clean_name_1}</h4>
            </div>
            <div className='secondStock' style={{marginLeft:20}}>
                <h4 >{data?.clean_name_2}</h4>
            </div>
        </div>
        <div className='comparision'>
            
            <div className='firstStock'>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Stock Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Day Low</td>
                        <td>{data?.stock_price?.day_low_1}</td>
                    </tr>
                    <tr>
                        <td>Day High</td>
                        <td>{data?.stock_price?.day_high_1}</td>
                    </tr>
                </table>

                <table className='tableContainerShort'>
                    <tr>
                        <td>Year Low</td>
                        <td>{data?.stock_price?.year_low_1}</td>
                    </tr>
                    <tr>
                        <td>Year High</td>
                        <td>{data?.stock_price?.year_high_1}</td>
                    </tr>
                    <tr>
                        <td>Yearly Change</td>
                        <td>{data?.stock_price?.yearly_change_1}</td>
                    </tr>
                </table>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Revenue</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Revenue Per Share</td>
                        <td>{data?.revenue?.revenue_per_share_1}</td>
                    </tr>
                    <tr>
                        <td>5 Year Revenue Growth</td>
                        <td>{data?.revenue?.five_yr_revenue_growth_1}</td>
                    </tr>
                    <tr>
                        <td>10 Year Revenue Growth</td>
                        <td>{data?.revenue?.ten_yr_revenue_growth_1}</td>
                    </tr>
                </table>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Profit</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Gross Profit Margin</td>
                        <td>{data?.profit?.gross_profit_margin_1}</td>
                    </tr>
                    <tr>
                        <td>Operating Profit Margin</td>
                        <td>{data?.profit?.operating_profit_margin_1}</td>
                    </tr>
                    <tr>
                        <td>Net Profit Margin</td>
                        <td>{data?.profit?.net_profit_margin_1}</td>
                    </tr>
                </table>
            </div>
            <div className='secondStock'>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Stock Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Day Low</td>
                        <td>{data?.stock_price?.day_low_2}</td>
                    </tr>
                    <tr>
                        <td>Day High</td>
                        <td>{data?.stock_price?.day_high_2}</td>
                    </tr>
                </table>
                <table className='tableContainerShort'>
                    <tr>
                        <td>Year Low</td>
                        <td>{data?.stock_price?.year_low_2}</td>
                    </tr>
                    <tr>
                        <td>Year High</td>
                        <td>{data?.stock_price?.year_high_2}</td>
                    </tr>
                    <tr>
                        <td>Yearly Change</td>
                        <td>{data?.stock_price?.yearly_change_2}</td>
                    </tr>
                </table>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Revenue</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Revenue Per Share</td>
                        <td>{data.revenue.revenue_per_share_2}</td>
                    </tr>
                    <tr>
                        <td>5 Year Revenue Growth</td>
                        <td>{data.revenue.five_yr_revenue_growth_2}</td>
                    </tr>
                    <tr>
                        <td>10 Year Revenue Growth</td>
                        <td>{data.revenue.ten_yr_revenue_growth_2}</td>
                    </tr>
                </table>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Profit</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Gross Profit Margin</td>
                        <td>{data.profit.gross_profit_margin_2}</td>
                    </tr>
                    <tr>
                        <td>Operating Profit Margin</td>
                        <td>{data.profit.operating_profit_margin_2}</td>
                    </tr>
                    <tr>
                        <td>Net Profit Margin</td>
                        <td>{data.profit.net_profit_margin_2}</td>
                    </tr>
                </table>
            </div>
        </div>

        <hr />

        <div className='comparision'>
                <div className='firstStock'>
                    <h4 >{data?.clean_name_1}</h4>
                </div>
                <div className='secondStock' style={{marginLeft:20}}>
                    <h4 >{data?.clean_name_2}</h4>
                </div>
            </div>
        <div className='comparision'>
            <div className='firstStock'>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Financial Ratios</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>P/E ratio</td>
                        <td>{data.financial_ratios.pe_ratio_1}</td>
                    </tr>
                    <tr>
                        <td>PEG ratio</td>
                        <td>{data.financial_ratios.peg_ratio_1}</td>
                    </tr>
                    <tr>
                        <td>P/B ratio</td>
                        <td>{data.financial_ratios.pb_ratio_1}</td>
                    </tr>
                    <tr>
                        <td>ROE</td>
                        <td>{data.financial_ratios.roe_1}</td>
                    </tr>
                </table>

                <table className='tableContainerShort'>
                    <tr>
                        <td>Payout ratio</td>
                        <td>{data.financial_ratios.payout_ratio_1}</td>
                    </tr>
                    <tr>
                        <td>Current ratio</td>
                        <td>{data.financial_ratios.current_ratio_1}</td>
                    </tr>
                    <tr>
                        <td>Quick ratio</td>
                        <td>{data.financial_ratios.quick_ratio_1}</td>
                    </tr>
                    <tr>
                        <td>Cash ratio</td>
                        <td>{data.financial_ratios.cash_ratio_1}</td>
                    </tr>
                </table>                
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Dividend</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Dividend Yield</td>
                        <td>{data.dividend.dividend_yield_1}</td>
                    </tr>
                    <tr>
                        <td>5 Year Dividend Yield</td>
                        <td>{data.dividend.five_year_dividend_growth_1}</td>
                    </tr>
                    <tr>
                        <td>10 Year Dividend Yield</td>
                        <td>{data.dividend.ten_year_dividend_growth_1}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}><a style={{textDecoration:"underline"}} className='stockName' href={"/c/" + data?.url_add_on_1}>{data?.clean_name_1} Dividend History</a></td>
                    </tr>
                </table>
            </div>
            <div className='secondStock'>
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Financial Ratios</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>P/E ratio</td>
                        <td>{data.financial_ratios.pe_ratio_2}</td>
                    </tr>
                    <tr>
                        <td>PEG ratio</td>
                        <td>{data.financial_ratios.peg_ratio_2}</td>
                    </tr>
                    <tr>
                        <td>P/B ratio</td>
                        <td>{data.financial_ratios.pb_ratio_2}</td>
                    </tr>
                    <tr>
                        <td>ROE</td>
                        <td>{data.financial_ratios.roe_2}</td>
                    </tr>
                </table>

                <table className='tableContainerShort'>
                    <tr>
                        <td>Payout ratio</td>
                        <td>{data.financial_ratios.payout_ratio_2}</td>
                    </tr>
                    <tr>
                        <td>Current ratio</td>
                        <td>{data.financial_ratios.current_ratio_2}</td>
                    </tr>
                    <tr>
                        <td>Quick ratio</td>
                        <td>{data.financial_ratios.quick_ratio_2}</td>
                    </tr>
                    <tr>
                        <td>Cash ratio</td>
                        <td>{data.financial_ratios.cash_ratio_2}</td>
                    </tr>
                </table>
                
                <table className='tableContainerShort'>
                    <thead>
                        <tr>
                            <th>Dividend</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr>
                        <td>Dividend Yield</td>
                        <td>{data.dividend.dividend_yield_2}</td>
                    </tr>
                    <tr>
                        <td>5 Year Dividend Yield</td>
                        <td>{data.dividend.five_year_dividend_growth_2}</td>
                    </tr>
                    <tr>
                        <td>10 Year Dividend Yield</td>
                        <td>{data.dividend.ten_year_dividend_growth_2}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}><a style={{textDecoration:"underline"}} className='stockName' href={"/c/" + data?.url_add_on_2}>{data?.clean_name_2} Dividend History</a></td>
                    </tr>
                </table>
            </div>
        </div>

        <div className='introSection'>
            <h2>{data.a_or_b.title}</h2>
            <p>{data.a_or_b.text}</p>
        
            <h3>{data.dividend_investors.title}</h3>
            <p>{data.dividend_investors.text}</p> 

            <h3>{data.value_investors.title}</h3>
            <p>{data.value_investors.text}</p>
            
            <h3>{data.growth_investors.title}</h3>
            <p>{data.growth_investors.text}</p>
            
            <h3>{data.retail_investors.title}</h3>
            <p>{data.retail_investors.text}</p>
        
        </div>
        </div>
        <div style={{background:"#B3B9CC40", padding:"10px 0"}}>
            <ComparisonCarousel url={stockVsStock} />
        </div>

        <div style={{width:"100%", padingBottom:40}}>
            <NewsCarousel />
        </div>
    </div>
    )}
    else {
        return (
            <div>Loading..</div>
        )
  }
}
