import React, {useState, useEffect} from 'react'
import "./search.css";
import axiosInstance from '../../utils/axiosInstance';
import { Helmet } from 'react-helmet';

export default function SearchPage() {
    const [result, setResult] = useState();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const s = params.get('s');

    const getSearchResult = async () => {
      const resposne = await axiosInstance.get("/symbol/search", {
        params: {clean_name: s}
      })
      const data = resposne.data.data;
      setResult(data);
    }

    useEffect(() => {
      getSearchResult();
    })
  return (
    <div className='searchPageContainer'>
    {/* <Helmet>
      <title>{"You searched for " + s}</title>
    </Helmet> */}
      <h2>Search Result</h2>
      {result? result.map((ele) => (
        <div className='searchResult'>
            <h3><a href={"/c/" + ele.url_add_on}>{ele.clean_name}</a></h3>
            <p>{ele.description}</p>
        </div>
      )) : 
      <div>No search result found</div>
      }
    </div>
  )
}
