import React from 'react';
import './contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div className="contact-form">
    {/* <Helmet>
      <title>Contact - idividends</title>
    </Helmet> */}

      <h2>Contact</h2>
      <form>
        <div className="form-group">
          <label htmlFor="name">Name <span className="required">*</span></label>
          <div className="name-inputs">
            <input type="text" id="first-name" name="first-name" placeholder="First" required />
            <input type="text" id="last-name" name="last-name" placeholder="Last" required />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email <span className="required">*</span></label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="channel">How did you hear about us? <span className="required">*</span></label>
          <select id="channel" name="channel" required>
            <option value="" disabled selected>Channel</option>
            <option value="internet">Google</option>
            <option value="friend">Social Media</option>
            <option value="advertisement">A Friend</option>
            <option value="other">Other Website</option>
            <option value="other">TV</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="message">Comment or Message <span className="required">*</span></label>
          <textarea id="message" name="message" required></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Contact;
