import React from 'react'
import { Helmet } from 'react-helmet'

export default function PrivacyPolicy() {
  return (
    <div>
    {/* <Helmet>
      <title>Privacy Policy - idividends</title>
    </Helmet> */}
    <div style={{color: "#181818", marginBottom: "5rem", fontWeight: 500, lineHeight: 1.2, padding: "20px 12%", display: "flex", flexDirection: "column"}}>
      <h2 style={{margin: "35px 0", fontSize:"32px"}}>Cookie Policy & Privacy Statement</h2>
      <p style={{fontSize:"16px", paddingLeft:"10px"}}>At iDividendStock, we are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you visit our website or use our services.</p>
      <ol style={{margin:0}}>
        <li>Information We Collect We may collect certain personal information that you voluntarily provide to us, such as your name, email address, and any other information you choose to submit while using our website or services. Additionally, we may automatically collect certain information about your device and usage patterns, including IP address, browser type, and access times.</li>
        <li>Use of Information The information we collect is used for the following purposes:</li>
      </ol>
      <p style={{fontSize:"16px", paddingLeft:"10px"}}>To provide and maintain our services to you.<br />
        To personalize your experience and improve our website. <br />
        To communicate with you, respond to your inquiries, and send you relevant updates. <br/>
        To monitor website usage and analyze user behavior to enhance our services.<br/>
        To comply with legal obligations and protect our rights and the rights of our users.</p>
      <ol start="3" style={{margin:0}}>
        <li>Data Security We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your data.</li>
        <li>Data Retention We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy and as required by law. When we no longer need your information, we will securely dispose of it.</li>
        <li>Cookies and Tracking Technologies We may use cookies and similar tracking technologies to enhance your user experience, analyze usage patterns, and gather demographic information about our users. You can manage your cookie preferences through your browser settings.</li>
        <li>Third-Party Links Our website may contain links to third-party websites or services. Please note that we are not responsible for the privacy practices of these third parties, and we encourage you to review their privacy policies before providing any personal information.</li>
        <li>Children’s Privacy Our services are not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have unintentionally collected information from a child under 13, please contact us immediately, and we will take appropriate steps to remove the data.</li>
        <li>Changes to this Privacy Policy We reserve the right to update or modify this Privacy Policy at any time without prior notice. The revised policy will be posted on this page, and the date of the latest revision will be indicated at the top of the page. Please check this page periodically for any changes.</li>
        <li>Contact Us If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at .</li>
      </ol>
    </div>
    </div>
  )
}
