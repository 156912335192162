import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './similarStocks.css';
import { TbArrowUpRight } from "react-icons/tb";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

export default function SimilarStocks({stocks}) {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <MdKeyboardArrowRight color="#000" />,
        prevArrow: <MdKeyboardArrowLeft color="#000" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 756,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true
            }
          }
        ]
    };
    const shortenPara = (para, count) => {
      if(!para) return '';
      const words = para.split(' ');
      if(words.length <= count) {
          return para;
      }
      return words.slice(0, count).join(' ') + '...';
    }
    if(stocks.length > 1)
      return (
        <div className="similarStocksCarouselContainer">
          <h2>Similar <span>Stocks</span></h2>
          <Slider {...settings}>
            {stocks.map((item, index) => (
              <div key={index} className='similarStocksSlide'>
              <div style={{background:"white", padding:"10px 20px 20px"}}>
                <div className='stockLogo'>
                    <img style={{width:100, height:100}} src={item.image} alt="Stock Logo" />
                </div>
                <h3>{item.clean_name}</h3>
                <div className='tagContainer'>
                    {item.tags.map((item, index2) => (
                        <div style={{marginTop:0, marginBottom:0}} className='tag' key={index2}>{item}</div>
                    ))}
                </div>
                
                <p>{shortenPara(item.description, 30)}</p>
                <p></p>
                <div className='readMoreContainer'><a className='readMore' href={"/c/" + item.url_add_on}>Read More <TbArrowUpRight size={24} /> </a></div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )
      else if (stocks.length === 1) return (
        <div className="similarStocksCarouselContainer">
          <h2>Similar <span>Stocks</span></h2>
          <div className='similarStocksSlide'>
            <div style={{background:"white", padding:"10px 20px 20px"}}>
              <div className='stockLogo'>
                  <img style={{width:100, height:100}} src={stocks[0].image} alt="Stock Logo" />
              </div>
              <h3>{stocks[0].clean_name}</h3>
              <div className='tagContainer'>
                {stocks[0].tags.map((item, index2) => (
                  <div style={{marginTop:0, marginBottom:0}} className='tag' key={index2}>{item}</div>
                ))}
              </div>
                
              <p>{shortenPara(stocks[0].description, 30)}</p>
              <div className='readMoreContainer'>
                <a className='readMore' href={"/c/" + stocks[0].url_add_on}>Read More <TbArrowUpRight size={24} /></a>
              </div>
            </div>
          </div>
        </div>
      )
      else return (<div></div>)
      
}
