import React, {useState, useEffect} from 'react'
import logo from "../../../images/logo-nav.png";
import { IoSearch } from "react-icons/io5";
import "./header.css";
import { MdMenu } from "react-icons/md";

export default function Header() {
  const [open, setOpen] = useState(false);
  const openDrawer = () => {
    setOpen(!open);
  }
  return (
    <div className="header">
      <a href="/"><img src={logo} alt="Logo" className='logo' /></a>
      <div className="menu">
        <ul>
          <li className='headerLink'><a href="/all-stocks">All Stocks</a></li>
          <li className='headerLink'><a href="/all-stock-comparisons">Comparisons</a></li>
          <li className='headerLink'><a href="/aristocrats">Dividend Aristocrats</a></li>
          {/* <li className='headerLink'><a href="/monthly">Monthly Dividend Stocks</a></li> */}
          <li className='headerLink'><a href="/dividend-calendar">Dividend Calendar</a></li>
          {/* <li className='headerLink'><a href="/faqs">FAQ</a></li> */}
          <li className='headerLink'><a href="/blog">Blogs</a></li>
        </ul>
      </div>
      <div className="search" >
        <IoSearch size={27} />
        <form method="get" action='/search'>
          <input type="text" name='s' placeholder="Search Stock" />
        </form>
      </div>
      <div className='hamburger' onClick={openDrawer}>
        <MdMenu size={30} style={{cursor:"pointer"}} />
      </div>
      <div className={open? "menuDrawer" : "hide"}>
        <ul style={{paddingInlineStart: 0}}>
          <div className='menuSearchContainer'>
            <div className={"menuSearch"} >
              <IoSearch size={27} />
              <form method="get" action='/search'>
                <input type="text" name='s' placeholder="Search Stock" />
              </form>
            </div>
          </div>
          
          <li className='linkMenu'><a href="/all-stocks">All Stocks</a></li>
          <li className='linkMenu'><a href="/all-stock-comparisons">Comparisons</a></li>
          <li className='linkMenu'><a href="/aristocrats">Dividend Aristocrats</a></li>
          {/* <li className='linkMenu'><a href="/monthly">Monthly Dividend Stocks</a></li> */}
          <li className='linkMenu'><a href="/dividend-calendar">Dividend Calendar</a></li>
          {/* <li className='linkMenu'><a href="/faqs">FAQ</a></li> */}
          <li className='linkMenu'><a href="/blog">Blogs</a></li>
        </ul>
      </div>
      {/* <div className='circle-shadow'></div> */}
    </div>
  );
};