import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import "./graph.css"

const BarChartComponent = ({fiveYear, tenYear, legendName, color, type}) => {
  
  fiveYear = fiveYear.map(item => ({
    ...item,
    value: parseFloat(item.dividend.replace(/[^\d.-]/g, ''))
  }))
  tenYear = tenYear.map(item => ({
    ...item,
    value: parseFloat(item.dividend.replace(/[^\d.-]/g, ''))
  }))

  const [data, setData] = useState([]);
    
  const handleToggle = (years) => {
    if (years === '5Y') {
      setData([...fiveYear].reverse());
    } else {
      setData([...tenYear].reverse());
    }
  };
  useEffect(() => {
    setData([...fiveYear].reverse());
  }, []);
  
  const dollarFormatter = (value) => `$${value}`;
  const percentFormatter = (value) => `${value}%`;

  // Custom legend renderer
  const renderLegend = () => (
    <div style={{ textAlign: 'center', marginBottom: 20 }}>
      <div style={{ display: 'inline-block', marginRight: 10, backgroundColor: color, width: 40, height: 15 }}></div>
      <span style={{ verticalAlign: 'top' }}>{legendName}</span>
    </div>
  );

  return (
    <div className='graphContainer'>
      <h3 style={{ textAlign: 'center', color: '#4a4a4a' }}>
        {/* {type==="dividend"? "Dividend $ per ordinary share" : ""} */}
      </h3>
      <div style={{ textAlign: 'center', marginBottom: 10 }}>
        <button onClick={() => handleToggle('5Y')} style={{ marginRight: 10 }}>5Y</button>
        <button onClick={() => handleToggle('10Y')}>10Y</button>
      </div>
      <div style={{ width: '100%', flex: '1 1 auto', maxHeight: '100%' }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis 
            dataKey="year"
            axisLine={true} 
            tickLine={false} 
          />
          <YAxis 
            tickFormatter={type === "dividend" ? dollarFormatter : percentFormatter} 
            axisLine={true} 
            tickLine={false} 
          />
          <Tooltip/>
          <Legend content={renderLegend} verticalAlign="top" align="center" />
          <Bar dataKey="value" fill={color} name="Value" />
        </BarChart>
      </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartComponent;
