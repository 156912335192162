import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './comparisonCarousel.css';
import compareImg from "../../../images/Vector.png"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import axiosInstance from '../../../utils/axiosInstance';

export default function ComparisonCarousel({url}) {
  const [dividendData, setDividendData] = useState([])
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <MdKeyboardArrowRight color="#000" />,
        prevArrow: <MdKeyboardArrowLeft color="#000" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 756,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true
            }
          }
        ]
    };
    const fetchDividendData = async() => {
      try {
        const response = await axiosInstance.get("versus/getComparisons?url_add_on=" + url);
        setDividendData(response.data.data);
      }catch(err) {
        console.error(err);
      }
    }
    useEffect(() => {
      fetchDividendData();
    }, [])
    
      return (
        <div className="comparisionCarouselContainer">
          <h2>Comparision</h2>
          <Slider {...settings}>
            {dividendData.map((item, index) => (
              <div className='comparisonSlide'>
                <div style={{background:"white"}}>
                  <div className='logoContainer'>
                      <img style={{ width:40 }} src={item.image_1} alt="image1"/>
                      <img style={{ width:40 }} src={compareImg} alt="compare"/>
                      <img style={{ width:40 }} src={item.image_2} alt="image2"/>
                  </div>
                  <div className='titleContainer'><h3>{item.title}</h3></div>
                  <div style={{padding:"10px 0 30px 0", display:"flex", justifyContent:"center"}}><a className='comparisonlink' href={"/compare/" + item.url_add_on}>{item.title.split(": ")[1]}</a></div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      );
}
