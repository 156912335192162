import React, {useState, useEffect} from 'react'
import "./home.css"
import Table from "../Layout/Table/table"
import { TbArrowUpRight } from "react-icons/tb";
import NewsCarousel from '../Layout/News/news';
import axiosInstance from '../../utils/axiosInstance';
import {Helmet} from "react-helmet";

export default function Home() {
  const [aristocrats, setAristocrats] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [highYield, setHighYield] = useState([]);
  

  const fetchDividendAristocrats = async () => {
    try {
      const resposne = await axiosInstance.get("/symbol/aristocrats/DividendAristocrats")
      const data = resposne.data.data;
      const tableData = data.map(announcement => [
        announcement.url_add_on,
        announcement.clean_name,
        announcement.ex_div_date,
        announcement.dividend_amount,
        announcement.pay_date
      ]);
      setAristocrats(tableData);
    }catch(err) {
      console.error(err);
    }
  }
  const fetchDividendAnnouncements = async () => {
    try {
      const resposne = await axiosInstance.get("/symbol/aristocrats/HighDividendAristocrats")
      const data = resposne.data.data;
      const tableData = data.map(announcement => [
        announcement.url_add_on,
        announcement.clean_name,
        announcement.ex_div_date,
        announcement.dividend_amount,
        announcement.pay_date
      ]);
      setAnnouncements(tableData);
    }catch(err) {
      console.error(err);
    }
  }
  const fetchMonthlydividend = async () => {
    try {
      const resposne = await axiosInstance.get("/symbol/aristocrats/MonthlyDividendStocks")
      const data = resposne.data.data;
      const tableData = data.map(announcement => [
        announcement.url_add_on,
        announcement.clean_name,
        announcement.ex_div_date,
        announcement.dividend_amount,
        announcement.pay_date
      ]);
      setMonthly(tableData);
    }catch(err) {
      console.error(err);
    }
  }
  const fetchHighYield = async () => {
    try {
      const resposne = await axiosInstance.get("/symbol/aristocrats/HighDividendAristocrats")
      const data = resposne.data.data;
      const tableData = data.map(announcement => [
        announcement.url_add_on,
        announcement.clean_name,
        announcement.ex_div_date,
        announcement.dividend_amount,
        announcement.pay_date
      ]);
      setHighYield(tableData);
    }catch(err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchDividendAristocrats();
    fetchDividendAnnouncements();
    fetchMonthlydividend();
    fetchHighYield();
  }, [])
  return (
    <div className='homeMain'>
            {/* <Helmet>
              <title>idividends - The Overview for Stocks paying Dividends</title>
            </Helmet> */}
      <div className='homeContainer'>

        <h2>
          Last paid out <span className='underline'>Dividend Aristocrats</span> 
        </h2>
        <Table data={aristocrats} headings={["Stock", "Ex-Div date", "Dividend amount", "Pay date"]} isNameRequired={true} />
        <div style={{width:"100%", textAlign:"right", marginTop: "20px", marginBottom: "70px"}}>
          <a href="/aristocrats" className='heroBtn'>All Dividend Aristocrats<TbArrowUpRight size={26} className='arrowIcon'/></a>
        </div>

        <h2>Latest <span className='underline'>Dividend Announcements</span></h2>
        <Table data={announcements} headings={["Stock", "Ex-Div date", "Dividend amount", "Pay date"]} isNameRequired={true} />

        <h2>High <span className='underline'>Dividend Stocks</span></h2>
        <Table data={highYield} headings={["Stock", "Ex-Div date", "Dividend amount", "Pay date"]} isNameRequired={true} />

        <h2>Last paid out <span className='underline'>Monthly Dividend Stocks</span></h2>
        <Table data={monthly} headings={["Stock", "Ex-Div date", "Dividend amount", "Pay date"]} isNameRequired={true} />
        <div style={{width:"100%", textAlign:"right", marginTop: "20px"}}>
          <a href="/monthly" className='heroBtn'>All Monthly Dividend Stocks<TbArrowUpRight size={26} className='arrowIcon'/></a>
        </div>

      </div>
      <div className='newsContainer'>
        <NewsCarousel />
      </div>

    </div>
  )
}
