import React, {useState, useEffect} from 'react'
import axiosInstance from '../../utils/axiosInstance'
import { Helmet } from 'react-helmet'

export default function AllComparisons() {
    const [stockList, setStockList] = useState([])

    const fetchStockList = async () => {
      try {
        const resposne = await axiosInstance.get("/versus/getAllVsList")
        setStockList(resposne.data.data);
      }catch(err) {
        console.error(err);
      }
    }
    useEffect(() => {
      fetchStockList();
    })
  return (
    <div className='allStocksContainer'>
    {/* <Helmet>
      <title>All Stock Comparisions</title>
    </Helmet> */}
      <h2>All Stock Comparisions</h2> 
      <div>
        <ul>
            {stockList.map((item, index) => (
                <li key={index}><a href={"/compare/" + item.url_add_on}>{item.title}</a></li>
            ))}
        </ul>
      </div>
      
    </div>
  )
}
