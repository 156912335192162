import React, {useState, useEffect} from 'react'
import "./admin.css"
import axiosInstance from '../../utils/axiosInstance'
import Cookies from 'js-cookie';

export default function Admin() {
    const [blogs, setBlogs] = useState([]);
    
    const verifyPass = async() => {
        const pass = window.prompt("Enter password for admin mode");
        try {
            const response = await axiosInstance.post("/blog/login", 
                {password: pass}
            )
            console.log(response);
            if(response.status === 200 && response.data.message === "Verified"){
                Cookies.set("user", "admin");
                window.location.reload();
            }else {
                window.location.replace("/");
                return;
            }
        }catch(err) {
            console.log(err.response);
            window.location.replace("/");
            return;
        }
        
    }

    const fetchAllBlogs = async() => {
        const response = await axiosInstance.get("/blog/readAll?admin=true");
        setBlogs(response.data.data);
    }
    const handleBlogDelete = async(name) => {
        const userConfirmed = window.confirm(`Are you sure you want to delete /${name} Blog?`);
        if(userConfirmed) {
            try {
                const response = await axiosInstance.delete("/blog/delete", {params: {url_add_on: name}});
                console.log(response);
                window.location.reload();
            }catch(err) {
                console.log(err);
            }
        }else {
            return;
        }
    }
    useEffect(() => {
        if(Cookies.get('user') === "admin") {
            fetchAllBlogs();
        }else {
            verifyPass();
        }    
    }, [])
  return (
    <div className='adminContainer'>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <h2>All Blogs</h2>
            <a href="/admin/blogs">Add blogs</a>
            <a href="/admin/change-password">Change Password</a>
        </div>
      
      <table>
        <thead>
            <tr>
                <th>S. no.</th>
                <th>Title</th>
                <th>Tags</th>
                <th>Update</th>
                <th>Delete</th>
                <th>Last Update</th>          
            </tr>
        </thead>
            {blogs.map((ele, index) => {
                return (
                    <tr>
                        <td>{index+1}</td>
                        <td><a href={"/" + ele.url_add_on}>{ele.title}</a></td>
                        <td>{ele.tags.join(', ')}</td>
                        <td><a className='updateBtn' href={"/admin/blogs/update/" + ele.url_add_on}>Update</a></td>
                        <td>{ele.is_active? 
                            <button style={{background: "lightgreen",}} className='deleteBtn' onClick={() => handleBlogDelete(ele.url_add_on)}>Active</button>
                            : 
                            <button style={{background: "red", color:"white"}} className='deleteBtn' onClick={() => handleBlogDelete(ele.url_add_on)}>Inactive</button>
                            }
                        </td>
                        <td>{ele.last_updated}</td>
                    </tr>
                )
            })}
      </table>
    </div>
  )
}
