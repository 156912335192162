import React, {useState, useEffect} from 'react'
import axiosInstance from '../../utils/axiosInstance'
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function CompanyTag() {
    const [stockList, setStockList] = useState([])
    const {tag} = useParams();

    const fetchStockList = async () => {
      try {
        const resposne = await axiosInstance.get("/symbol/searchTags/" + tag);
        setStockList(resposne.data.data);
      }catch(err) {
        console.error(err);
      }
    }
    const convertTagBack = (tagName) =>{
      if(!tagName) return "";
      let words = tagName.split('-');

      for (let i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      let heading = words.join(' ');
      return heading;
    }

    const convertUrlTag = (url) => {
      if(!url) return "";
      return url
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    useEffect(() => {
      fetchStockList();
    })
  return (
    <div className='allStocksContainer'>
    {/* <Helmet>
      <title>{convertUrlTag(tag) + " Archives -"}</title>
    </Helmet> */}
      <h2>All {convertTagBack(tag)} Stocks</h2> 
      <div>
        <ul>
            {stockList.map((item, index) => (
                <li key={index}><a href={"/c/" + item.url_add_on}>{item.clean_name}</a></li>
            ))}
        </ul>
      </div>
      
    </div>
  )
}
