import React, { useEffect, useState } from 'react';
import './ChangePassword.css';
import axiosInstance from '../../utils/axiosInstance';
import Cookies from 'js-cookie';

const ChangePassword = () => {
  const [masterPassword, setMasterPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('New password and confirm password do not match');
      return;
    }
    try {
        const response = await axiosInstance.post("/blog/changePassword", {
            password:newPassword, master_password: masterPassword,
        });
        console.log(response.data);
        setMessage('Password successfully changed!');
    }catch(err) {
        console.log(err.response.data.message)
        setMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    if(Cookies.get('user') !== "admin") {
        window.location.replace("/");
        return;
    }
  }, [])

  return (
    <div className="change-password-container">
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Master Password</label>
          <input
            type="password"
            value={masterPassword}
            onChange={(e) => setMasterPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm New Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {message && <p className="message">{message}</p>}
        <button type="submit" className="submit-button">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;
