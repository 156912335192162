import React, { useState, useEffect, useRef } from 'react';
import './postBlog.css'; // Import your CSS file
import JoditEditor from 'jodit-react';
import axiosInstance from '../../../utils/axiosInstance'
import Cookies from 'js-cookie';

const BlogForm = () => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [tags, setTags] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [stockInput, setStockInput] = useState('');
  const [password, setPassword] = useState('');
  const [content, setContent] = useState('');
  const [contentToc, setContentToc] = useState('');
  const [meta, setMeta] = useState('');
  const editor = useRef(null);
  const editorToc = useRef(null);

  const handleAddTag = () => {
    if (tagInput && !tags.includes(tagInput)) {
      setTags([...tags, tagInput]);
      setTagInput('');
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleAddStock = () => {
    if (stockInput && !stocks.includes(stockInput)) {
      let stockArr;
      stockArr = stockInput.split(", ");
      setStocks([...stocks, ...stockArr]);
      setStockInput('');
    }
  };

  const handleDeleteStock = (stockToDelete) => () => {
    setStocks(stocks.filter((stock) => stock !== stockToDelete));
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    if(!title) {
      alert("Title is missing");
      return;
    }else if (!content && content.length) {
      alert("Content is missing");
      return;
    }else if(!password) {
      alert("Incorrect password");
      return;
    }else if (!contentToc && contentToc.length) {
      alert("Table of contents is missing");
      return;
    }else if (!url && url.length) {
      alert("URL is missing");
      return;
    }

    try {
      const response = await axiosInstance.post("/blog/create", {
        title: title, content:content, tags:tags, table_of_contents:contentToc, url_add_on: url, metatag:meta, symbols:stocks,
      }, {headers : {'password': password}});
      if(response.data.data) {
        alert(response.data.data)
      }else if (response.data.error) {
        alert(response.data.error);
      }
    }catch(err) {
      alert(err.response.data.error);
    }
    
  };

  useEffect(() => {
    if(Cookies.get("user") === "admin") {
      return;
    }else {
      window.location.replace("/");
    }
  }, [])

  return (
    <div className="container" style={{flexDirection:"column"}}>
      <h2>Create a Blog Post</h2>
      <form onSubmit={handleSubmit} className="blog-form">
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>URL title (without spaces)</label>
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Tags</label>
          <div className="tagInput">
            <input
              type="text"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              className="form-control"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddTag();
                }
              }}
            />
            <button type="button" onClick={handleAddTag} className="tagSubmitBtn">
              Add Tag
            </button>
          </div>
          <div className="tagList">
            {tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
                <button
                  type="button"
                  onClick={handleDeleteTag(tag)}
                  className="deleteTag"
                >
                  &times;
                </button>
              </span>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label>Stocks for Table (In block letters) (Optional)</label>
          <div className="tagInput">
            <input
              type="text"
              value={stockInput}
              onChange={(e) => setStockInput(e.target.value)}
              className="form-control"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddStock();
                }
              }}
            />
            <button type="button" onClick={handleAddStock} className="tagSubmitBtn">
              Add Stock
            </button>
          </div>
          <div className="tagList">
            {stocks.map((stock, index) => (
              <span key={index} className="tag">
                {stock}
                <button
                  type="button"
                  onClick={handleDeleteStock(stock)}
                  className="deleteTag"
                >
                  &times;
                </button>
              </span>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label>Meta Tag</label>
          <textarea
            value={meta}
            onChange={event => setMeta(event.target.value)}
            placeholder="Enter meta tag content here"
            rows="10"
          />
        </div>
        <div className="form-group">
          <label>Content</label>
          <JoditEditor
            ref={editor}
            value={content}
            // config={config}
            tabIndex={1} 
            onChange={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </div>
        <div className="form-group">
          <label>Table of contents</label>
          <JoditEditor
            ref={editorToc}
            value={contentToc}
            // config={config}
            tabIndex={1} 
            onChange={newContent => setContentToc(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <button type="submit" className="btnSubmit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default BlogForm;
