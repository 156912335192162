import React, {useState, useEffect} from 'react'
import "./allStocks.css"
import axiosInstance from '../../utils/axiosInstance'
import { Helmet } from 'react-helmet'

export default function AllStocks() {
    const [page, setPage] = useState(0);
    let pagesCalled = [];
    const perPage = 250;
    const [stockList, setStockList] = useState([])

    const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop +700 >=
          document.documentElement.scrollHeight
        ) {
          setPage((prevPage) => prevPage + 1);
        }
    };
    
    useEffect(() => {
      const fetchStockList = async (page) => {
        if(!pagesCalled.includes(page)) {
          pagesCalled.push(page);
          console.log(pagesCalled)
          const resposne = await axiosInstance.get("/symbol/all", {
            params: {page: page, per_page: perPage}
          })
          setStockList(prevItems => [...prevItems, ...resposne.data.data]);
        }
      }
      fetchStockList(page);
    }, [page])
    
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    
  return (
    <div className='allStocksContainer'>
    {/* <Helmet>
      <title>All Dividend Stocks</title>
      
    </Helmet> */}
      <h2>All Dividend Stocks</h2> 
      <div>
        <ul>
            {stockList.map((item, index) => (
                <li key={index}><a href={"/c/" + item.url_add_on}>{item.clean_name}</a></li>
            ))}
        </ul>
      </div>
      
    </div>
  )
}
