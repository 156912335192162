import React, {useState, useEffect, useLayoutEffect, useRef} from 'react'
import { useParams } from 'react-router-dom';
import "./stockInfo.css"
import Table from "../Layout/Table/table"
import BarChartComponent from "../Layout/Graph/graph"
import BlogFaq from '../Layout/BlogFaq/blogFaq'
import SimilarStocks from '../Layout/SimilarStocks/similarStocks'
import FinancialOverview from '../Layout/FinancialOverview/overview'
import axiosInstance from '../../utils/axiosInstance'
import DividendCalc from '../DividendCalc/dividendCalc'
import {Helmet} from "react-helmet";

export default function StockInfo() {
    const {stockName} = useParams();
    const [isSticky, setIsSticky] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [dividendHistory, setDividendHistory] = useState([]);
    const contentRef = useRef();

    const handleScroll = () => {
        if (window.scrollY > 80) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
    };
    
    const fetchStockData = async() => {
      try {
        const response = await axiosInstance.get("/symbol/completeData?url_add_on=" + stockName);
        const announcements = response.data.data.dividend_history;
        const dividendType = response.data.data.dividend_type;
        // const tag = response.data.data.tags;
        const tableData = announcements.map(announcement => [
          announcement.date,
          announcement.dividend,
          dividendType,
          announcement.paymentDate
        ]);
        console.log(response.data.data);
        // setTags(tag);
        setData(response.data.data);
        setDividendHistory(tableData);
      }catch(err) {
        console.log(err);
      }
    }

    const convertTagUrl = (tag) => {
      if(!tag) return "/";
      tag = tag.replace(" ", "-");
      tag = tag.toLowerCase();
      return "/company-tag/"+tag;
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        fetchStockData();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  // if(!symbol) {
  //   return (
  //     <div></div>
  //   )
  // }
  return (
    <div>
    {/* <Helmet>
      <title>{cleanName + " Dividend: History, Dates, Yield & Payments"}</title>
      <meta name="description" content={cleanName + " pays a dividend, find the latest dividend history for Walmart. Shareholders who owned Walmart will receive dividend payment."} />
    </Helmet> */}
    <div className="container">
      <div className={`sidebar ${isSticky ? 'sticky' : ''}`}>
        <div className='index'>
          <h2>Table of Contents</h2>
          <ul>
            <li><a href="#dividend-announcement">1. Dividend Announcement</a></li>
            {dividendHistory?.length? <li><a href="#dividend-history">2. Dividend History</a></li> : <></> }
            {data?.annual_dividends?.length? <li><a href="#dividend-per-year">3. Per Year</a></li> : <></> }
            {data?.dividend_growth?.length? <li><a href="#dividend-growth">4. Dividend Growth</a></li> : <></> }
            <li><a href="#dividend-yield">{dividendHistory?.length? 5 : 2}. Dividend Yield</a></li>
            <li><a href="#financial-ratios">{dividendHistory?.length? 6 : 3}. Financial Ratios</a></li>
            <li><a href="#faq">{dividendHistory?.length? 7 : 4}. Dividend FAQ</a></li>
          </ul>
        </div>
        <div className='indexMob'>
            <div className="accordion-item-aristocrats">
            <button style={{background:"white", borderBottom:"1px solid #ccc", paddingBottom:7}} onClick={() => {setOpen(!open)}}>
                <div style={{display:"flex", justifyContent:"space-between" }}>
                Table of Contents <i className={`fas ${open ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
                </div></button>
              <div className={`dropdown ${open ? 'show' : ''}`}
                style={{ height: open ? contentRef.current.scrollHeight : '0px' }}>
                <ul ref={contentRef}>
                  <li><a href="#dividend-announcement">1. Dividend Announcement</a></li>
                  {dividendHistory?.length? <li><a href="#dividend-history">2. Dividend History</a></li> : <></> }
                  {data?.annual_dividends?.length? <li><a href="#dividend-per-year">3. Per Year</a></li> : <></> }
                  {data?.dividend_growth?.length? <li><a href="#dividend-growth">4. Dividend Growth</a></li> : <></> }
                  <li><a href="#dividend-yield">{dividendHistory?.length? 5 : 2}. Dividend Yield</a></li>
                  <li><a href="#financial-ratios">{dividendHistory?.length? 6 : 3}. Financial Ratios</a></li>
                  <li><a href="#faq">{dividendHistory?.length? 7 : 4}. Dividend FAQ</a></li>
                </ul>
              </div>
            </div>
          </div>
      </div>
      <div className="content">
        <div className='companyIntro'>
            {data?.img_url? <img className="stockinfoImage" src={data.img_url} alt="company-logo"/> : <></> }
            {data?.description? <p>{data.description}</p> : <></> }
        </div>
        {data?.announcements?.length? 
          <div id="dividend-announcement">
          <h2 style={{fontSize:"2rem"}}><span className='underline'>{data.clean_name}</span> Dividend Announcement</h2>
          <div className='announcemnetSection'>
              <p> 
                {data.announcements.map((ele, index) => {
                  return (
                    <span>• {ele} <br /></span>
                  )
                })}
              </p>
          </div> 
          </div>
          : <></>
        }
        
        <div className='dividendHistory' id="dividend-history">
            {data?.tags?.length? <h2><span className='underline'>{data.clean_name}</span> Dividend History</h2> : <></> }
            <div className="tags">
                <h6>Tags: 
                {data?.tags?.map((tag, index) => {
                  return (
                    <span className="tag"><a href={convertTagUrl(tag)}>{tag}</a></span>
                  )
                })}
                </h6>
            </div>
           {dividendHistory?.length? <Table data={dividendHistory} headings={["Ex-Div date", "Dividend amount", "Dividend type", "Pay date"]} isNameRequired={false} />: <></>} 
        </div>
        {data?.annual_dividends?.length? <div id="dividend-per-year">
            <h2><span className='underline'>{data.clean_name}</span> Dividend per year</h2>
            <BarChartComponent fiveYear={data?.annual_dividends.slice(0,5)} tenYear={data?.annual_dividends} color={"blue"} legendName={"Dividend per ordinary share"} type={"dividend"} />
        </div>: <></>
        }
        {data?.dividend_growth?.length? <div id="dividend-growth">
            <h2><span className='underline'>{data.clean_name}</span> Dividend growth</h2>
            <BarChartComponent fiveYear={data?.dividend_growth.slice(0,5)} tenYear={data?.dividend_growth} color={"#00b962"} legendName={"Growth Percentage"} type={"growth"} />
        </div> : <></>
        }
        
        <div id="dividend-yield">
            {data?.dividend_yield_content? <DividendCalc cleanName={data.clean_name} content={data.dividend_yield_content} /> : <></> }
        </div>
        {data?.financials_information? 
          <div id="financial-ratios">
            <h2><span className='underline'>{data.clean_name}</span> Financial Ratios</h2>
            <FinancialOverview financials = {data.financials_information}/>
          </div>
        : <></>}
        <div className='dividendFaq' id="faq">
            {data?.symbol? <BlogFaq symbol = {data.symbol} heading={data.clean_name} /> : <></>}
        </div>
      </div>
      <div className="empty-section"></div>
      
    </div>
        {/* <div className='dividendPolicySection'>
            <h2><span className='underline'>Walmart</span> Dividend policy</h2>
            <p>Walmart’s dividend policy reflects its commitment to returning value to shareholders while maintaining financial stability. The retail giant typically pays dividends on a quarterly basis, providing shareholders with a reliable income stream. Over the years, Walmart has consistently increased its dividend payments, demonstrating its financial strength and confidence in future earnings. The company’s dividend yield, historically competitive, attracts income-focused investors. Walmart’s prudent financial management ensures dividends are sustainable, allowing it to reinvest in growth initiatives while rewarding shareholders. This balanced approach aligns with the company’s long-term strategy of delivering shareholder value and maintaining its status as a retail industry leader.</p>
        </div> */}
        
        <div className='similarDividendStocks'>
            {data?.similar_symbols? <SimilarStocks stocks={data.similar_symbols} /> : <></>}
        </div>
    </div>
  )
}
