import React from 'react';
import './overview.css';

const FinancialOverview = ({financials}) => {
  return (
    <div className="overview-container">
      {/* <h2 style={{fontSize:24, textAlign:"center", marginBottom:"2rem"}}>Abbott Holding NV ADR Financial Data Overview</h2> */}
      <div className="overview-content">
        <div className="overview-column">
          <div className="overview-item">
            <span className="item-label">P/E ratio</span>
            <span className="item-value">{financials.peratiottm}</span>
          </div>
          <div className="overview-item">
            <span className="item-label">PEG ratio</span>
            <span className="item-value">{financials.pegratiottm}</span>
          </div>
          <div className="overview-item">
            <span className="item-label">P/B ratio</span>
            <span className="item-value">{financials.pbratiottm}</span>
          </div>
          <div className="overview-item">
            <span className="item-label">ROE</span>
            <span className="item-value">{financials.roettm}</span>
          </div>
        </div>
        <div className="overview-column">
          <div className="overview-item">
            <span className="item-label">Payout ratio</span>
            <span className="item-value">{financials.payoutratiottm}</span>
          </div>
          <div className="overview-item">
            <span className="item-label">Current ratio</span>
            <span className="item-value">{financials.currentratiottm}</span>
          </div>
          <div className="overview-item">
            <span className="item-label">Quick ratio</span>
            <span className="item-value">{financials.quickratiottm}</span>
          </div>
          <div className="overview-item">
            <span className="item-label">Cash Ratio</span>
            <span className="item-value">{financials.cashratiottm}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialOverview;
