import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './news.css';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import axiosInstance from '../../../utils/axiosInstance';

const NewsCarousel = () => {
    const [newsData, setNewsData] = useState([]);
    const fetchNewsData = async() => {
      try {
        const response = await axiosInstance.get("/blog/readNews");
        
        const news = [];
        const parser = new DOMParser();
        for(var i in response.data.data) {
          const content = response.data.data[i].content;
          const doc = parser.parseFromString(content, 'text/html');
          const firstP = doc.querySelector('p');
          const textContent = firstP?.textContent;
          news.push({title: response.data.data[i].title, description: textContent, link: "/" + response.data.data[i].url_add_on})
        }
        setNewsData(news);
      }catch(err) {
        console.error(err);
      }
    }
    useEffect(() => {
      fetchNewsData();
    }, [])
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <MdKeyboardArrowRight color="#888" />,
        prevArrow: <MdKeyboardArrowLeft color="#888" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true
            }
          }
        ]
      };
    
      const shortenPara = (para, count) => {
        if(!para) return '';
        const words = para.split(' ');
        if(words.length <= count) {
            return para;
        }
        return words.slice(0, count).join(' ') + '...';
      }
    
      if(newsData.length > 1) return (
        <div className="carousel-container">
        {newsData.length ? 
        <div>
          <h2>Dividend <span>news</span></h2>
          <Slider {...settings}>
            {newsData.map((item, index) => (
              <a key={index} className="carouselSlide" href={item.link}>
                <h4>{item.title}</h4>
                <p>{shortenPara(item.description, 30)}</p>
              </a>
            ))}
          </Slider>
          </div> : <></>
        }
        </div>
      )
      else if(newsData.length === 1) return (
        <div className="carousel-container">
          <div>
          <h2>Dividend <span>news</span></h2>
            <a className="carouselSlide" href={newsData[0].link}>
              <h4>{newsData[0].title}</h4>
              <p>{shortenPara(newsData[0].description, 30)}</p>
            </a>
          </div>
        </div>
      )
      else return (<></>)
};

export default NewsCarousel;
